import React, { useState } from 'react';
import '@shopify/polaris/styles.css';

import {
  ChevronDownMinor
} from '@shopify/polaris-icons';

import { Icon, Popover, ActionList, Avatar } from '@shopify/polaris';
import CargonizerSettingsModal from './CargonizerSettingsModal';

import { 
  shopDetails,
} from '../interfaces';

interface Props {
  shopDetails: shopDetails,
  setShopDetails: Function,
  handleUpdateAgreements: Function,
  setShowInfoPage: (flag: boolean) => void,
}

const Header = ({
  shopDetails,
  setShopDetails,
  handleUpdateAgreements,
  setShowInfoPage,
}: Props) => {
  const [isActionsListOpen, setIsActionsListOpen] = useState(false);
  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleActionList = () => {
    setIsActionsListOpen(!isActionsListOpen)
  }

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsActionsListOpen(false);
  }


  const actionsListActivator = (
    <div 
      onClick={() => {
        handleToggleActionList();
      }}
      className="profile"
    >
      <Avatar customer name={`${shopDetails.name}`} size="small"/>
      <div className="profile-info">
        <span className="user-name"> 
          {shopDetails.name}
        </span>
        <span className="user-role">
          Administrator
        </span>
      </div>
      <div className="btn-container">
        <button className="btn-open-action-list" >
          <Icon source={ChevronDownMinor} />
        </button>
      </div>
    </div>
  );

  return (
    <header className="header">
      <div>
        <img alt="leino-icon" src='/assets/leino-icon.png' height={49} width={49} />
        <h1>Leino</h1>
        </div>
      <div className="profile-wrapper">
        <div>
          <Popover 
            active={isActionsListOpen} 
            activator={actionsListActivator}
            onClose={() => {
              handleToggleActionList();
            }}
          >
            <ActionList
              items={[
                {
                  content: 'Cargonizer settings',
                  onAction: () => {
                    handleToggleModal();
                  },
                },
                {
                  content: 'Instructions',
                  onAction: () => {
                    setShowInfoPage(true);
                    handleToggleActionList();
                  },
                },
              ]}
            />
          </Popover>
        </div>
      </div>
      <CargonizerSettingsModal 
        isModalOpen={isModalOpen} 
        handleToggleModal={handleToggleModal}
        setShopDetails={setShopDetails}
        shopDetails={shopDetails}
        handleUpdateAgreements={handleUpdateAgreements}
      />
    </header>
  );
}

export default Header;
