import React from 'react';
import '@shopify/polaris/styles.css';
import { Button, Heading } from '@shopify/polaris'

interface Props {
  confirmUrl: string,
}

const ReactivateSubscription = ({
  confirmUrl,
}: Props) => {
  return (
    <div className="reactivate-subscription">
      <Heading>Subscription is deactivated</Heading>
      <a href={confirmUrl} rel="noopener noreferrer" target="_blank">
        <Button primary >
          Reactivate subscription
        </Button>
      </a>
    </div>
  );
}

export default ReactivateSubscription;
