import React, { useState, useCallback } from 'react';
import '@shopify/polaris/styles.css';

import { Modal, TextContainer, TextField } from '@shopify/polaris';

import Utils from '../utils';
import { shopDetails } from '../interfaces';

interface Props {
  isModalOpen: boolean,
  handleToggleModal: Function,
  setShopDetails: Function,
  shopDetails: shopDetails,
  handleUpdateAgreements: Function,
}

const CargonizerSettingsModal = ({
  isModalOpen, 
  handleToggleModal,
  setShopDetails,
  shopDetails,
  handleUpdateAgreements,
}: Props) => {
  // text field
  const [apiKeyValue, setApiKeyValue] = useState(shopDetails?.cargonizer?.apiKey || '');
  const [senderIdValue, setSenderIdValue] = useState(shopDetails?.cargonizer?.senderId || '');
  const handleChangeApiKey = useCallback((newValue) => setApiKeyValue(newValue), []);
  const handleChangeSenderId = useCallback((newValue) => setSenderIdValue(newValue), []);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        handleToggleModal();
      }}
      title="Cargonizer settings"
      primaryAction={{
        content: 'OK',
        onAction: () => {
          if (apiKeyValue && senderIdValue) {
            Promise.all([
              Utils.putShop({apiKey: apiKeyValue, senderId: senderIdValue, callback: ({shopDetails} : { shopDetails: shopDetails}) => {
                setShopDetails(shopDetails);
                handleUpdateAgreements();
              }})
            ]).then(() => {
              handleToggleModal();
            })
          }
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            handleToggleModal();
          },
        },
      ]}
    >
      <div className="cargo-set-modal-wrapper"> 
        <Modal.Section>
          <TextContainer>
          
          <TextField 
            label="Api Key" 
            value={apiKeyValue} 
            placeholder="Value" 
            onChange={handleChangeApiKey} 
          />

          <TextField 
            label="Sender ID" 
            value={senderIdValue} 
            placeholder="Value" 
            onChange={handleChangeSenderId} 
          />
      
          </TextContainer>
        </Modal.Section>
      </div>
    </Modal>
  )
}

export default CargonizerSettingsModal;
