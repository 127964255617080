import {
  API_URL,
  TOKEN,
  CHARGE_ID,
  ORDER_STATUS_UNFULFILLED,
  ORDER_STATUS_FULFILLED,
  SUBSCRIPTION_STATUS_IS_NOT_ACCEPTED,
  SELECTED_LOCATION,
  SELECTED_CARRIER,
  SELECTED_PRODUCT,
} from "./consts";

import {
  postOrdersArgs,
  getOrdersArgs,
  Order,
  getServicePartnersParams,
  getAgreementsArgs,
  getShopDetailsArgs,
  getServicePartnersArgs,
  postConsignmentsArgs,
  putShopArgs,
  getLocationsArgs,
  CustomError,
} from "./interfaces";

const Utils = {
  // cookie
  setCookie: (fieldName: string, fieldValue: string) => {
    let updatedCookie =
      encodeURIComponent(fieldName) + "=" + encodeURIComponent(fieldValue);
    document.cookie = updatedCookie + " ;Secure; SameSite=None";
  },
  getCookie: (fieldName: string) => {
    let matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
          fieldName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },

  // local storage
  setLocalStorage: (fieldName: string, fieldValue: string | object) => {
    localStorage.setItem(fieldName, JSON.stringify(fieldValue));
  },
  getLocalStorage: (fieldName: string) => {
    const returnValue = localStorage.getItem(fieldName);
    return returnValue ? JSON.parse(returnValue) : returnValue;
  },
  removeLocalStorage: (fieldName: string) => {
    localStorage.removeItem(fieldName);
  },

  // requests
  getContextLink: ({ callback }: { callback: Function }) => {
    const token = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${API_URL}/shop/link`, {
      method: "GET",
      headers: requestHeaders,
    })
      .then((response: any) => response.text())
      .then((response) => {
        if (response) {
          callback({ contextLink: response });
        }
      });
  },

  getActivate: (chargeId: String) => {
    const token = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${API_URL}/billing/activate?charge_id=${chargeId}`, {
      method: "GET",
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors !== SUBSCRIPTION_STATUS_IS_NOT_ACCEPTED) {
          if (!Utils.isInIframe()) {
            Utils.getContextLink({
              callback: ({ contextLink }: { contextLink: string }) => {
                window.location.replace(contextLink);
              },
            });
          } else {
            window.location.reload();
          }
        }
      });
  },

  getShopDetails: ({ callback }: getShopDetailsArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    fetch(`${API_URL}/shop`, {
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        callback({ shopDetails: response });
      })
      .catch(() => {
        callback({ shopDetails: {} });
      });
  },

  isInIframe: () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },

  postSubscribe: ({ callback }: { callback: Function }) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    const body = {
      returnUrl: window.location.href.split("?")[0],
    };

    fetch(`${API_URL}/billing/subscribe`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        Utils.removeLocalStorage(CHARGE_ID);
        if (response?.id) {
          Utils.setLocalStorage(CHARGE_ID, response.id);
          const redirectUrl = response.confirmationUrl;

          if (!Utils.isInIframe()) {
            window.location.replace(redirectUrl);
          } else {
            window.top.location.href = redirectUrl;
            setTimeout(() => {
              callback(redirectUrl);
            }, 5000);
          }
        }
      });
  },

  putShop: ({ apiKey, senderId, callback }: putShopArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);
    requestHeaders.set("Content-Type", `application/json`);

    const body = {
      cargonizer: {
        apiKey,
        senderId,
      },
    };

    fetch(`${API_URL}/shop`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        Utils.removeLocalStorage(SELECTED_LOCATION);
        Utils.removeLocalStorage(SELECTED_CARRIER);
        Utils.removeLocalStorage(SELECTED_PRODUCT);
        callback({ shopDetails: response });
      });
  },

  getServicePartners: ({
    countryCode,
    postCode,
    carrier,
  }: getServicePartnersArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    let url = new URL(`${API_URL}/delivery/service-partners`);
    const params: getServicePartnersParams = {
      country: countryCode,
      postcode: postCode,
      carrier,
    };

    Object.keys(params).forEach((key: string) =>
      url.searchParams.append(key, params[key])
    );

    return fetch(`${url}`, {
      method: "GET",
      headers: requestHeaders,
    }).then((response) => response.json());
  },

  getLocations: ({ callback }: getLocationsArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    const url = new URL(`${API_URL}/shop/locations`);

    return fetch(`${url}`, {
      method: "GET",
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        callback({ locationsList: response });
      });
  },

  postConsignments: ({
    locationId,
    transportAgreemntId,
    product,
    estimate = false,
    print,
    transfer = false,
    services,
    servicePartner,
    freightPayerAddress,
  }: postConsignmentsArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    const body = {
      locationId,
      transportAgreement: transportAgreemntId,
      product: product.identifier,
      estimate,
      print,
      transfer,
      services: {
        identifier: services.identifier,
        attributes: services.attributes[0],
      },
      servicePartner,
      freightPayerAddress: {
        customerNumber: `${freightPayerAddress.id}`,
        name: freightPayerAddress.firstName,
        postcode: freightPayerAddress.defaultAddress.zip,
        country: freightPayerAddress.defaultAddress.countryCode,
        address1: freightPayerAddress.defaultAddress.address1,
        address2: freightPayerAddress.defaultAddress.address2,
        city: freightPayerAddress.defaultAddress.city,
        contactPerson: "contactPerson",
        email: freightPayerAddress.email || "email",
        mobile: "mobile",
        phone: freightPayerAddress.phone,
        fax: "fax",
        agentNumber: "agentNumber",
      },
    };

    const url = new URL(`${API_URL}/delivery/consignments`);

    return fetch(`${url}`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(body),
    }).then((response) => response.json());
  },

  postOrders: ({
    transferAutomatically,
    unprintedOrders,
    iterationCallback,
    finalCallback,
    isAutoprintEnabled,
    selectedCarrier,
    selectedProduct,
    transportAgreemntId,
    locationId,
    errorsList,
    setErrorsList,
  }: postOrdersArgs) => {
    const lastUnprintedOrder = unprintedOrders.pop();

    if (lastUnprintedOrder) {
      const cookie = Utils.getCookie(TOKEN);
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Authorization", `Bearer ${cookie}`);
      requestHeaders.set("Content-type", "application/json");
      const orderId = lastUnprintedOrder.id.toString();

      Promise.all([
        Utils.getServicePartners({
          countryCode: lastUnprintedOrder?.shippingAddress?.countryCode,
          postCode: lastUnprintedOrder?.shippingAddress?.zip,
          carrier: selectedCarrier?.identifier,
        }),
      ])
        .then((response) => {
          // response [0] is list of service partners
          const servisePartners = response[0]?.servicePartners;
          const servicePartner = servisePartners[0] && {
            number: servisePartners[0].number,
            name: servisePartners[0].name,
            city: servisePartners[0].city,
            country: servisePartners[0].country,
            postcode: servisePartners[0].postcode,
            address1: servisePartners[0].address1,
          };
          const print = isAutoprintEnabled;

          const body = {
            locationId: parseInt(locationId),
            transportAgreement: transportAgreemntId,
            product: selectedProduct.identifier,
            print,
            transfer: transferAutomatically,
            servicePartner,
          };

          fetch(`${API_URL}/orders/${orderId}/fulfillments`, {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((response) => {
              let currentOrderIndex = -1;

              errorsList.forEach((error: CustomError, index: number) => {
                if (error.order === orderId) {
                  currentOrderIndex = index;
                }
              });

              if (
                response.status < 200 ||
                response.status > 300 ||
                !response.status
              ) {
                const error =
                  response?.response?.error ||
                  response?.error ||
                  response?.response?.errors?.error ||
                  "unhandled error";
                const newErrorsListValue = errorsList;
                if (currentOrderIndex !== -1) {
                  newErrorsListValue[currentOrderIndex] = {
                    order: orderId,
                    errors: error,
                  };
                } else {
                  newErrorsListValue.push({
                    order: orderId,
                    errors: error,
                  });
                }
                setErrorsList(newErrorsListValue);
              } else {
                errorsList.splice(currentOrderIndex, 1);
              }

              iterationCallback({ unprintedOrdersValue: unprintedOrders });
              Utils.postOrders({
                locationId,
                unprintedOrders,
                iterationCallback,
                finalCallback,
                isAutoprintEnabled,
                transferAutomatically,
                selectedCarrier,
                selectedProduct,
                transportAgreemntId,
                errorsList,
                setErrorsList,
              });
            })
            .catch(() => {
              iterationCallback({ unprintedOrdersValue: [] });
            });
        })
        .catch(() => {
          iterationCallback({ unprintedOrdersValue: unprintedOrders });
          Utils.postOrders({
            locationId,
            unprintedOrders,
            iterationCallback,
            finalCallback,
            transferAutomatically,
            isAutoprintEnabled,
            selectedCarrier,
            selectedProduct,
            transportAgreemntId,
            errorsList,
            setErrorsList,
          });
        });
    } else {
      // update orders list
      finalCallback();
    }
  },

  getOrders: ({
    callback,
    setPadingationLinks,
    requestLink = null,
  }: getOrdersArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    fetch(
      `${API_URL}/orders/?${
        requestLink
          ? `page_info=${requestLink}`
          : "status=any&financial_status=any&fulfillment_status=any"
      }&limit=50`,
      {
        headers: requestHeaders,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setPadingationLinks({
          prevLink: response.previous,
          nextLink: response.next,
        });

        const orders: Array<Order> = [];
        response.data.forEach((order: any) => {
          if (order) {
            orders.push({
              name: order.billingAddress?.name,
              id: order.id,
              number: order.orderNumber,
              status: order.fulfillmentStatus
                ? ORDER_STATUS_FULFILLED
                : ORDER_STATUS_UNFULFILLED,
              price: order.totalPrice,
              date: order.createdAt && Utils.formatDate(order.createdAt),
              shippingAddress: order.shippingAddress,
              lineItems: order.lineItems,
              customer: order.customer,
              freight:
                order.shippingLines?.lenght <= 1
                  ? order.shippingLines?.[0]?.title
                  : order.shippingLines
                      .map((el: { title: string }) => el.title)
                      .join(", "),
            });
          }
        });
        callback({ orders, success: true });
      })
      .catch(() => {
        callback({ orders: [], success: false });
      });
  },

  getAgreements: ({ callback }: getAgreementsArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    fetch(`${API_URL}/delivery/agreements`, {
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors) {
          callback({ errors: response.errors, agreements: [] });
        } else {
          callback({ agreements: response, errors: [] });
        }
      })
      .catch(() => {
        callback({ agreements: [], errors: [] });
      });
  },

  formatDate: (date: any) => {
    const formatDate = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = formatDate.getDate();
    const monthIndex = formatDate.getMonth();
    const year = formatDate.getFullYear();
    const hour = formatDate.getHours();
    const minute = formatDate.getMinutes();

    return `${hour}:${minute} ${monthNames[monthIndex]} ${day}, ${year}`;
  },
};

export default Utils;
