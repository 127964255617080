import React from 'react';
import '@shopify/polaris/styles.css';

import { Banner } from '@shopify/polaris';


interface Props {
  text: string,
}

const WarningBanner = ({
  text,
}: Props) => {
  return (
    <Banner title={text} status="critical">
    </Banner>
  );
}

export default WarningBanner;
