import React from 'react';
import '@shopify/polaris/styles.css';


interface Props {
  setShowInfoPage: (flag: boolean) => void,
}

const InfoPage = ({
  setShowInfoPage,
}: Props) => {
  const backBtnText = '<- Back to app'
  return (
    <div className="info-page">
      <button 
        onClick={() => {
          setShowInfoPage(false);
        }}
        className="back-btn"
      >
        {backBtnText}
      </button>
      <h2>Leino setup instructions</h2>
      <h3>Preconditions</h3>
      <div className="row">
        <img alt="info_image_1" src='/assets/info_image_1.png' height={84} width={104} />
        <p className="margin-left-36px max-width-375px">
          You need to have an account at logistra. You can fix that by going to <a href="https://www.logistra.no/" rel="noopener noreferrer" target="_blank">www.logistra.no</a> and sign up for a plan.
        </p>
      </div>
      <h3>Set up Leino</h3>
      <p className="max-width-475px">
        To set up Leino, you have to log into www.cargonizer.no with your credentials you got from Logistra, and then you have to go to “Innstillinger”
      </p>
      <img className="margin-top-15px" alt="info_image_2" src='/assets/info_image_2.png' height={107} width={441} />
      <p className="margin-top-15px max-width-655px">
        On the “Innstillinger page you find “API Key”. Add this API key in Leino by clicking your shopname on the top right in Leino and add it to “API Key”
      </p>
      <img className="margin-top-45px" alt="info_image_3" src='/assets/info_image_3.png' height={87} width={472} />
      <p className="margin-top-15px max-width-655px">
        On the “Innstillinger page you find “Avsendere”. Add this ID in Leino by clicking your shopname on the top right in Leino and add it to “Sender Id”
      </p>
      <h3>Using Leino</h3>
      <p className="max-width-655px">
       To use Leino, simply select an “Unfulfilled” order (or many) and then click “Action” and “Fulfill items”. Then you can select if you want to print labels at the same time as you fulfill them by selecting “ Print automatically”. Then the label will be printed on the printer you got from Logistra when you signed up. An e-mail will be shipped to the customer with the tracking id. The email uses the template you already have created in Shopify
      </p>
      <h3>Request features</h3>
      <p>
        If you see that you want to adjust the app for your needs, please contact us by e-mail
      </p>
      {' '}
      <div className="email-link" >
        <a href="mailto:webmaster@example.com" rel="noopener noreferrer" target="_blank">thomas@then.no</a>
      </div>
    </div>
  );
}

export default InfoPage;
