import React, { useState, useEffect, Fragment, useCallback } from "react";
import "@shopify/polaris/styles.css";

import {
  Select,
  Modal,
  TextContainer,
  Checkbox,
  Banner,
} from "@shopify/polaris";
import Utils from "../utils";
import {
  Order,
  handleUpdatePrintedOrdersProps,
  Agreement,
  Carrier,
  selectOption,
  Product,
  CustomError,
} from "../interfaces";
import {
  ORDER_STATUS_UNFULFILLED,
  SELECTED_PRODUCT,
  SELECTED_CARRIER,
  SELECTED_LOCATION,
} from "../consts";

interface Props {
  isModalOpen: boolean;
  handleToggleModal: Function;
  selectedOrders: Array<Order>;
  handleUpdateOrdersList: Function;
  agreements: Array<Agreement>;
  carriers: Array<Carrier>;
  listCarriersOptions: Array<selectOption>;
  locationsList: Array<any>;
  errorsList: Array<CustomError>;
  setErrorsList: Function;
}

const FulfillOrderModal = ({
  isModalOpen,
  handleToggleModal,
  handleUpdateOrdersList,
  selectedOrders,
  agreements,
  carriers,
  listCarriersOptions,
  locationsList,
  errorsList,
  setErrorsList,
}: Props) => {
  const [selectedCarrier, setSelectedCarrier] = useState({
    name: "",
    identifier: "",
  });

  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    id: "",
  });

  const [selectedProduct, setSelectedProduct] = useState({
    name: "",
    identifier: "",
  });

  const handleSelectLocation = (locationIdentifier: string) => {
    const selectedLocationValue: Array<any> = locationsList.filter(
      (location: any) => location.id.toString() === locationIdentifier
    );
    if (selectedLocationValue[0]) {
      Utils.setLocalStorage(SELECTED_LOCATION, selectedLocationValue[0]);
      setSelectedLocation(selectedLocationValue[0]);
    }
  };

  const listLocationOptionsValue = locationsList?.map((location: any) => {
    return {
      label: location.name,
      value: location.id,
    };
  });

  const [isAutoprintEnabled, setIsAutoprintEnabled] = useState(true);
  const [transferAutomatically, setTransferAutomatically] = useState(true);
  const [transportAgreemntId, setTransportAgreemntId] = useState(0);
  const [unprintedOrdersQuantity, setUnprintedOrdersQuantity] = useState(0);
  const [unprintedOrdersNumber, setUnprintedOrdersNumber] = useState(0);
  const [printingInProcess, setPrintingInProcess] = useState(false);
  const [products, setProducts] = useState([
    {
      identifier: "",
      name: "",
    },
  ]);
  const [listProductsOptions, setListProductsOptions] = useState([
    {
      label: "",
      value: "",
    },
  ]);

  const unfulfilledOrders = selectedOrders.filter(
    (order) => order.status === ORDER_STATUS_UNFULFILLED
  );

  const handleSelectCarrier = useCallback(
    (carrierIdentifier: string) => {
      const selectedCarrier: Array<Carrier> = carriers.filter(
        (carrier: Carrier) => carrier.identifier === carrierIdentifier
      );
      const selectedAgreement: Array<Agreement> = agreements.filter(
        (agreement: Agreement) =>
          agreement.carrier.identifier === carrierIdentifier
      );
      const listProducts: Array<Product> = selectedAgreement[0].products;
      const listProductsOptions: Array<selectOption> = [];
      listProducts.forEach((product: Product) => {
        listProductsOptions.push({
          label: product.name,
          value: product.identifier,
        });
      });

      if (selectedCarrier[0]) {
        const setSelectedCarrierValue = {
          name: selectedCarrier[0].name,
          identifier: selectedCarrier[0].identifier,
        };
        setSelectedProduct({
          name: "",
          identifier: "",
        });
        Utils.removeLocalStorage(SELECTED_PRODUCT);
        setTransportAgreemntId(selectedAgreement[0].id);
        Utils.setLocalStorage(SELECTED_CARRIER, selectedCarrier[0]);
        setSelectedCarrier(setSelectedCarrierValue);
        setListProductsOptions(listProductsOptions);
        setProducts(listProducts);
      }
    },
    [carriers, agreements]
  );

  const handleSelectProduct = (productIdentifier: string) => {
    const selectedProductValue: Array<Product> = products.filter(
      (product: Product) => product.identifier === productIdentifier
    );
    if (selectedProductValue[0]) {
      Utils.setLocalStorage(SELECTED_PRODUCT, selectedProductValue[0]);
      setSelectedProduct(selectedProductValue[0]);
    }
  };

  const handleToggleAutoprint = () => {
    setIsAutoprintEnabled(!isAutoprintEnabled);
  };

  const handleUpdatePrintedOrders = ({
    unprintedOrdersValue,
  }: handleUpdatePrintedOrdersProps) => {
    setUnprintedOrdersQuantity(unprintedOrdersValue.length);
  };

  useEffect(() => {
    const previousSelectedLocation = Utils.getLocalStorage(SELECTED_LOCATION);
    if (
      previousSelectedLocation &&
      selectedLocation.id !== previousSelectedLocation.id &&
      locationsList.find(
        (location) => location?.id === previousSelectedLocation?.id
      )
    ) {
      setSelectedLocation(previousSelectedLocation);
    }

    const previousSelectedCarrier = Utils.getLocalStorage(SELECTED_CARRIER);
    if (
      previousSelectedCarrier &&
      selectedCarrier.identifier !== previousSelectedCarrier.identifier &&
      agreements[0] &&
      Object.keys(agreements[0]).length
    ) {
      handleSelectCarrier(previousSelectedCarrier.identifier);
    }

    const previousSelectedProduct = Utils.getLocalStorage(SELECTED_PRODUCT);
    if (
      previousSelectedProduct &&
      selectedProduct.identifier !== previousSelectedProduct.identifier
    ) {
      setSelectedProduct(previousSelectedProduct);
    }
  }, [
    agreements,
    handleSelectCarrier,
    locationsList,
    selectedCarrier.identifier,
    selectedLocation,
    selectedLocation.id,
    selectedProduct.identifier,
  ]);

  if (printingInProcess) {
    return (
      <Modal
        open={isModalOpen}
        onClose={() => {
          handleToggleModal();
        }}
        title="Fulfill orders"
      >
        <Modal.Section>
          <TextContainer>
            {unprintedOrdersQuantity
              ? `${
                  unprintedOrdersNumber - unprintedOrdersQuantity
                } of ${unprintedOrdersNumber} orders fulfilled`
              : `0 of ${unprintedOrdersNumber} orders fulfilled`}
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        if (errorsList.length !== 0) {
          handleUpdateOrdersList();
        }
        setErrorsList([]);
        handleToggleModal();
      }}
      title="Fulfill orders"
      primaryAction={{
        content: "OK",
        onAction: () => {
          if (unfulfilledOrders.length === 0) {
            handleToggleModal();
            handleUpdateOrdersList();
          } else if (
            selectedCarrier.name &&
            selectedProduct.name &&
            selectedLocation.id
          ) {
            setPrintingInProcess(true);
            setUnprintedOrdersNumber(unfulfilledOrders.length);

            Utils.postOrders({
              locationId: selectedLocation.id,
              transportAgreemntId,
              unprintedOrders: unfulfilledOrders,
              iterationCallback: handleUpdatePrintedOrders,
              finalCallback: () => {
                if (errorsList.length === 0) {
                  handleToggleModal();
                  handleUpdateOrdersList();
                }
                setPrintingInProcess(false);
                setUnprintedOrdersNumber(0);
              },
              transferAutomatically,
              isAutoprintEnabled,
              selectedCarrier,
              selectedProduct,
              errorsList,
              setErrorsList,
            });
          }
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            if (errorsList.length !== 0) {
              handleUpdateOrdersList();
            }
            setErrorsList([]);
            handleToggleModal();
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          {errorsList?.length ? (
            <Banner title="Something went wrong" status="critical">
              {errorsList.map((error: CustomError) => (
                <p>
                  <span>Order #{error.order}</span>
                  <br />
                  <span>
                    Errors:
                    <br />
                    {typeof error.errors === "string" ? (
                      <Fragment>
                        {error.errors}
                        <br />
                      </Fragment>
                    ) : (
                      error.errors.map((errorText: string) => {
                        return (
                          <span>
                            {errorText}
                            <br />
                          </span>
                        );
                      })
                    )}
                  </span>
                  --------
                </p>
              ))}
            </Banner>
          ) : null}
          <Select
            label="Carrier"
            options={listCarriersOptions}
            placeholder="Select..."
            onChange={handleSelectCarrier}
            value={selectedCarrier.identifier}
          />
          <Select
            label="Product"
            options={listProductsOptions}
            placeholder="Select..."
            onChange={handleSelectProduct}
            value={selectedProduct.identifier}
          />
          <Select
            label="Location"
            options={listLocationOptionsValue}
            placeholder="Select..."
            onChange={handleSelectLocation}
            value={selectedLocation.id}
          />

          <Checkbox
            label="Let Cargonizer automatically print"
            checked={isAutoprintEnabled}
            onChange={() => {
              handleToggleAutoprint();
            }}
          />
          <br />
          <Checkbox
            label="Transfer orders directly to the logistic company"
            checked={transferAutomatically}
            onChange={() => {
              setTransferAutomatically(!transferAutomatically);
            }}
          />
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default FulfillOrderModal;
