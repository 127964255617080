import React, { useState, useEffect } from 'react';
import '@shopify/polaris/styles.css';

import { DataTable, Tabs, Checkbox, Popover, ActionList, Button, Card } from '@shopify/polaris'

import WarningBanner from './WarningBanner';
import { Banner } from '@shopify/polaris';
import FulfillOrderModal from './FulfillOrderModal';

import { 
  Order,
  Tab,
  SelectedOrder,
  Carrier,
  selectOption,
  Agreement,
  CustomError,
  shopDetails,
} from '../interfaces';

import { 
  ORDER_STATUS_ALL,
  ORDER_STATUS_UNFULFILLED,
  ORDER_STATUS_FULFILLED,
  IS_INFO_CHECKED,
 } from '../consts';
import Utils from '../utils';

interface Props {
  orders: Array<Order>,
  handleUpdateOrdersList: Function,
  agreements: Array<Agreement>,
  carriers: Array<Carrier>,
  listCarriersOptions: Array<selectOption>,
  locationsList: Array<any>,
  errorsList: Array<CustomError>,
  setErrorsList: Function,
  shopDetails: shopDetails,
  isApiKeyInvalidFlag: boolean,
  handleLoadPrevOrders: () => void,
  handleLoadNextOrders: () => void,
  padingationLinks: {
    prevLink?: string;
    nextLink?: string;
  },
  setShowInfoPage: (flag: boolean) => void,
}

const OrdersTable = ({
  orders,
  handleUpdateOrdersList, 
  agreements,
  carriers,
  listCarriersOptions,
  locationsList,
  errorsList,
  setErrorsList,
  shopDetails,
  isApiKeyInvalidFlag,
  handleLoadPrevOrders,
  handleLoadNextOrders,
  padingationLinks,
  setShowInfoPage,
}: Props) => {
  const [isInfoChecked, setIsInfoChecked]: any = useState(Utils.getCookie(IS_INFO_CHECKED))
  const [selectedOrders, setSelectedOrders]: any = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isAllOrdersSelected, setIsAllOrdersSelected] = useState(false);
  const [isAllFulfilledOrdersSelected, setIsAllFulfilledOrdersSelected] = useState(false);
  const [isAllUnfulfilledOrdersSelected, setIsAllUnfulfilledOrdersSelected] = useState(false);
  // Action
  const [isActionsListOpen, setIsActionsListOpen] = useState(false);
  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const selectedOrdersValue: Array<SelectedOrder> = [];
    const firstOrder = selectedOrders[0];
    const selectedOrdersEmpty: boolean =  firstOrder === undefined;

    orders.forEach((order: Order) => {
      selectedOrdersValue.push({
        selected: false,
        status: order.status
      })
    });
    if (selectedOrdersEmpty && orders.length && selectedOrdersValue.length) {
      setSelectedOrders(selectedOrdersValue)
    }
  }, [orders, selectedOrders]);

  const handleChangeSelectedTab = (selectedTabIndex: number) => {
    setSelectedTabIndex(selectedTabIndex);
  }

  const handleToggleSelectedOrder = (selectedOrderID: number) => {
    // toggle selected param for the selectedOrderID
    const selectedOrdersValue = [...selectedOrders];
    selectedOrdersValue[selectedOrderID] = {
      selected: !selectedOrdersValue[selectedOrderID].selected,
      status: selectedOrdersValue[selectedOrderID].status,
    }
    setSelectedOrders(selectedOrdersValue);
  }

  const handleChangeSelectedOrders = (value: boolean, status: string) => {
    const selectedOrdersValue: Array<SelectedOrder> = [];
    let isAllOrdersSelectedValue = false;
    let isAllFulfilledOrdersSelectedValue = false;
    let isAllUnfulfilledOrdersSelectedValue = false; 
    
    if (orders.length) {
      selectedOrders.forEach((selectedOrder: SelectedOrder, i: number) => {
        const orderFulfilled = selectedOrder.status === ORDER_STATUS_FULFILLED;
        const orderUnfulfilled = selectedOrder.status === ORDER_STATUS_UNFULFILLED;
  
        switch (status) {
          case ORDER_STATUS_ALL: 
            selectedOrdersValue[i] = {
              status: selectedOrders[i].status,
              selected: value,
            }
            isAllOrdersSelectedValue = value;
            isAllFulfilledOrdersSelectedValue = isAllFulfilledOrdersSelected;
            isAllUnfulfilledOrdersSelectedValue = isAllUnfulfilledOrdersSelected;
            break;
          case ORDER_STATUS_UNFULFILLED: 
            if (orderUnfulfilled)  {
              selectedOrdersValue[i] = {
                status: selectedOrders[i].status,
                selected: value,
              }
            } else {
              selectedOrdersValue[i] = {
                status: selectedOrders[i].status,
                selected: selectedOrders[i].selected,
              }
            }
            isAllOrdersSelectedValue = isAllOrdersSelected;
            isAllFulfilledOrdersSelectedValue = isAllFulfilledOrdersSelected;
            isAllUnfulfilledOrdersSelectedValue = value;
            break; 
          case ORDER_STATUS_FULFILLED:
              if (orderFulfilled)  {
                selectedOrdersValue[i] = {
                  status: selectedOrders[i].status,
                  selected: value,
                }
              } else {
                selectedOrdersValue[i] = {
                  status: selectedOrders[i].status,
                  selected: selectedOrders[i].selected,
                }
              }
            isAllOrdersSelectedValue = isAllOrdersSelected;
            isAllFulfilledOrdersSelectedValue = value;
            isAllUnfulfilledOrdersSelectedValue = isAllUnfulfilledOrdersSelected;
            break; 
          default:
        }
      })
  
      setSelectedOrders(selectedOrdersValue);
      handleSelectAllOrders(isAllOrdersSelectedValue);
      handleSelectAllFulfilledOrders(isAllFulfilledOrdersSelectedValue);
      handleSelectAllUnfulfilledOrders(isAllUnfulfilledOrdersSelectedValue);
    }
  }

  const handleSelectAllOrders = (value: boolean) => {
    setIsAllOrdersSelected(value);
  }

  const handleSelectAllUnfulfilledOrders = (value: boolean) => {
    setIsAllUnfulfilledOrdersSelected(value);
  }

  const handleSelectAllFulfilledOrders = (value: boolean) => {
    setIsAllFulfilledOrdersSelected(value);
  }

  const handleToggleActionList = () => {
    setIsActionsListOpen(!isActionsListOpen);
  }

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsActionsListOpen(false);
  }

  let selectedAllValue = true;
  let selectedAllFulfilledValue = true;
  let selectedAllUnfulfilledValue = true;

  const rows: Array<Array<any>> = orders?.map((order: Order, i: number) => {
    const checkedValue: boolean = !!(selectedOrders[i]?.selected);

    return [ 
      <Checkbox
        label=""
        checked={checkedValue}
        onChange={() => {
          handleToggleSelectedOrder(i);
        }}
      />,
      orders[i].number,
      orders[i].name,
      <div className={`${orders[i].status}`}>
        {orders[i].status}
      </div>,
      orders[i].price,
      orders[i].freight,
      orders[i].date,
    ];
  })

  const tabs = [
    {
      id: 'all',
      content: 'All',
      tableData: '0',
      accessibilityLabel: 'All orders',
    },
    {
      id: 'unfulfilled',
      content: 'Unfulfilled', 
      tableData: '1',
      accessibilityLabel: 'Unfulfilled orders',
    },
    {
      id: 'fulfilled',
      content: 'Fulfilled orders',
      tableData: '2',
      accessibilityLabel: 'Fulfilled orders',
    },
  ];

  // Filter by fulfilled status for the tabs
  const selectedTab: Tab = tabs[selectedTabIndex];
  const selectedRows = rows.filter((row) => {
    return row[3].props.children === selectedTab.id || selectedTab.id === ORDER_STATUS_ALL;
  });
  // end

  // selected orders counter
  let selectedOrdersCounter = 0;
  let selectedUnfulfilledOrders: Array<Order> = [];

  if (selectedOrders.length !== 0) {
    selectedOrders.forEach((selectedOrder: SelectedOrder, i: number) => {
      const orderSelected = selectedOrders[i].selected;
      const orderUnfulfilled = selectedOrders[i].status === ORDER_STATUS_UNFULFILLED;

      if (orderSelected) {
        if (orderUnfulfilled) {
          selectedUnfulfilledOrders.push(orders[i]);
        }
        selectedOrdersCounter += 1;
      }
    }) 
  }
  // end

  // update selectedAll flags in the state
  selectedOrders.forEach((selectedOrder: SelectedOrder, i: number) => {
    const orderStatus: string = orders[i].status;
    const orderSelected = selectedOrders[i].selected;

    const orderUnfulfilled = orderStatus === ORDER_STATUS_UNFULFILLED;
    const orderFulfilled = orderStatus === ORDER_STATUS_FULFILLED;

    if (selectedOrders) {
      if (!orderSelected) {
        selectedAllValue = false;
      }
      if (!orderSelected && orderFulfilled) {
        selectedAllFulfilledValue = false;
      }
      if (!orderSelected && orderUnfulfilled) {
        selectedAllUnfulfilledValue = false;
      }
    }
  });

  if (isAllOrdersSelected !== selectedAllValue) {
    handleSelectAllOrders(selectedAllValue);
  }

  if (isAllFulfilledOrdersSelected !== selectedAllFulfilledValue) {
    handleSelectAllFulfilledOrders(selectedAllFulfilledValue);
  }

  if (isAllUnfulfilledOrdersSelected !== selectedAllUnfulfilledValue) {
    handleSelectAllUnfulfilledOrders(selectedAllUnfulfilledValue);
  }
  // end

  let headerCheckbox: boolean = false;
  if (orders.length) {
    switch(selectedTabIndex) {
      // all orders
      case 0:
        headerCheckbox = isAllOrdersSelected;
        break;
      // unfulfilled orders
      case 1:
        headerCheckbox = isAllUnfulfilledOrdersSelected;
        break;
      // fulfilled orders
      case 2:
        headerCheckbox = isAllFulfilledOrdersSelected;
        break;
      default:
    }  
  }
 
  const actionsListActivator = (
    <Button 
      onClick={() => {
        handleToggleActionList();
      }} 
      disclosure
    >
      Actions
    </Button>
  );

  const headerCheckboxElement: any = (
    <Checkbox
      label=""
      checked={headerCheckbox}
      onChange={() => {
        if (orders.length) {
          switch(selectedTabIndex) {
            case 0:
              handleChangeSelectedOrders(!isAllOrdersSelected, ORDER_STATUS_ALL);
              break;
            case 1:
              handleChangeSelectedOrders(!isAllUnfulfilledOrdersSelected, ORDER_STATUS_UNFULFILLED);
              break;
            case 2:
              handleChangeSelectedOrders(!isAllFulfilledOrdersSelected, ORDER_STATUS_FULFILLED);
              break;
            default:
          }
        }
      }}
    />
  );

  const handleCloseInfoBanner = () => {
    Utils.setCookie(IS_INFO_CHECKED, 'true');
    setIsInfoChecked(true);
  }

  const cargonizerApiKeyIsSetted = shopDetails.cargonizer?.apiKey;
  const disableFulfillment = !cargonizerApiKeyIsSetted || isApiKeyInvalidFlag || !selectedUnfulfilledOrders.length;

  return (
    <div className="table-wrapper">
      {!cargonizerApiKeyIsSetted && (
        <WarningBanner text="Cargonizer Api key is not set. Please click on the dropdown on the right side, click on “Cargonizer settings” and add the api key. The api key you find
        on Cargonizer under “Innstillinger/Settings”" />
      )}
      {isApiKeyInvalidFlag && (
        <WarningBanner text="Cargonizer Api key is not valid. Please click on the dropdown on the right side, click on “Cargonizer settings” and add the api key. The api key you find
        on Cargonizer under “Innstillinger/Settings”" />
      )}
      {!isInfoChecked && (
        <div className="polaris-info-banner">
          <Banner
            title="Welcome to Leino"
            action={{content: 'Learn more', onAction: () => {
              setShowInfoPage(true);
            }}}
            status="info"
            onDismiss={handleCloseInfoBanner}
          >
            <p>Learn about how Leino works and how to set it up</p>
          </Banner>
        </div>
      )}
      <Tabs tabs={tabs} selected={selectedTabIndex} onSelect={(selectedTabIndex: number) => {
        handleChangeSelectedTab(selectedTabIndex)}
      }>
        <div className="table-actions" style={{display: selectedOrdersCounter ? '': 'none'}}>
          <div className="actions-counter">
            <Checkbox
              label=""
              checked={true}
            />
            <span className="selected-orders-counter">
              {selectedOrdersCounter}
              {' '}
              selected
            </span>
          </div>
          <Popover 
            active={isActionsListOpen} 
            activator={actionsListActivator}
            onClose={() => {
              handleToggleActionList();
            }}
          >
            <ActionList
              items={[
                {
                  content: 'Fullfill items',
                  onAction: () => {
                    if (selectedUnfulfilledOrders.length) {
                      handleToggleModal();
                    }
                  },
                  disabled: disableFulfillment,
                },
              ]}
            />
          </Popover>
        </div>
        <Card>
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              headerCheckboxElement,
              'Orderid',
              'Name',
              'Status',
              'Order amount',
              'Freight option',
              'Date',
            ]}
            rows={selectedRows} 
          />
        </Card>
      </Tabs>
      { isModalOpen && (
        <FulfillOrderModal 
          isModalOpen={isModalOpen} 
          handleToggleModal={handleToggleModal}
          selectedOrders={selectedUnfulfilledOrders}
          handleUpdateOrdersList={handleUpdateOrdersList}
          agreements={agreements}
          carriers={carriers}
          listCarriersOptions={listCarriersOptions}
          locationsList={locationsList}
          errorsList={errorsList}
          setErrorsList={setErrorsList}
        />
      )}
      <div className="pagination-btns-wrapper">
        <div>
          {padingationLinks.prevLink && <Button onClick={handleLoadPrevOrders}>prev</Button>}
        </div>
        <div>
          {padingationLinks.nextLink && <Button onClick={handleLoadNextOrders}>next</Button>}
        </div>
      </div>
    </div>
  );
}

export default OrdersTable;
