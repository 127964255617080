export const API_URL = 'https://leino-shopify.herokuapp.com/api/v1';

export const ORDER_STATUS_ALL = 'all';
export const ORDER_STATUS_UNFULFILLED = 'unfulfilled';
export const ORDER_STATUS_FULFILLED = 'fulfilled';

// cookie values
export const TOKEN = 'token';
export const CHARGE_ID = 'charge_id';
export const IS_INFO_CHECKED = 'is_info_checked';

// local storage values
export const SELECTED_PRODUCT = 'selectedProduct';
export const SELECTED_CARRIER = 'selectedCarrier';
export const SELECTED_LOCATION = 'selectedLocation';

// response codes
export const SUBSCRIPTION_NOT_ACTIVE = 402;
export const SUBSCRIPTION_STATUS_IS_NOT_ACCEPTED = `This charge must have an 'accepted' status before it can be activated. Please redirect the shop owner to this charge's confirmation_url.`;