import React, { Fragment, useState, useEffect } from 'react';
import fetchIntercept from 'fetch-intercept';

import Utils from '../utils'
import OrdersTable from './OrdersTable';
import InfoPage from './InfoPage';
import Header from './Header';

import { 
  API_URL, 
  TOKEN,
  SUBSCRIPTION_NOT_ACTIVE, 
  CHARGE_ID,
} from '../consts';

import { 
  getOrdersCallbackArgs,
  getAgreementsCallbackArgs,
  Carrier,
  selectOption,
  Agreement,
  getShopDetailsArgsCallbackArgs,
  getLocationsArgsCallbackArgs,
} from '../interfaces';
import ReactivateSubscription from './ReactivateSubscription';


const OrdersList = () => {
  const [confirmUrl, setConfirmUrl] = useState('');
  const [showInfoPage, setShowInfoPage] = useState(false);

  fetchIntercept.register({
    response(response) {
      switch (response.status) {
        case SUBSCRIPTION_NOT_ACTIVE:
          const chargeId = new URL(window.location.href).searchParams.get(CHARGE_ID);
          if (chargeId && chargeId !== 'undefined' && window.location.href.includes(`${CHARGE_ID}=`)) {
            Utils.getActivate(chargeId);
          } else {
            Utils.postSubscribe({
              callback: (redirectUrl: string) => {
                setConfirmUrl(redirectUrl);
              }
            });
          }
          break;
        default:
      }
      return response
    }
  });

  const [isFetching, setIsFetching] = useState(true);
  const [isApiKeyInvalidFlag, setIsApiKeyInvalidFlag] = useState(false);
  const [orders, setOrders] : Array<any | Function>  = useState([]);
  // OrdersTable
  const [agreements, setAgreements] = useState([{}]);
  const [carriers, setCarriers]: any = useState([{}]);
  const [listCarriersOptions, setListCarriersOptions]: any = useState([{}]);
  // Header
  const [shopDetails, setShopDetails] = useState({
    id: '',
    name: '',
    cargonizer: {
      apiKey: '',
      senderId:  '',
    },
    subscription: {
      id: 0,
      name: '',
      price: '',
      status: '',
      test: true,
      trialDays: 0,
      billingOn: '',
      activatedOn: '',
      cancelledOn: '',
      trialEndsOn: '',
      createdAt: '',
      updatedAt: '',
      apiClientId: 0,
      returnUrl: '',
      confirmationUrl: '',
      decoratedReturnUrl: '',
    },
    url: '',
  });
  // Locations
  const [locationsList, setLocationsList] = useState([{}]);
  // Erros
  const [errorsList, setErrorsList] = useState([]);
  // links
  const [padingationLinks, setPadingationLinks] = useState({
    prevLink: '',
    nextLink: '',
  });

  const handleGetOrders = () => {
    Utils.getOrders({ callback : ({ orders }: getOrdersCallbackArgs) => {
      setOrders(orders);
      setIsFetching(false);
    }, setPadingationLinks});
  }

  const handleUpdateOrdersList = () => {
    if (!isFetching) {
      setIsFetching(true);
      handleGetOrders();
    }
  }

  const handleUpdateAgreements = () => {
    Utils.getAgreements({
      callback: ({ agreements, errors }: getAgreementsCallbackArgs) => {
        if (errors.length !== 0) {
          setIsApiKeyInvalidFlag(true);
        } else {
          const carriers: Array<Carrier> = [];
          const listCarriersOptions: Array<selectOption> = [];
          agreements.forEach((agreement: Agreement) => {
            carriers.push(agreement.carrier);
            listCarriersOptions.push({
              label: agreement.carrier.name,
              value: agreement.carrier.identifier,
            });
          });
  
          setAgreements(agreements);
          setCarriers(carriers);
          setListCarriersOptions(listCarriersOptions);
          setIsApiKeyInvalidFlag(false);
        }

        setIsFetching(false);
      }
    });
  }

  useEffect(() => {
    const queryParams = window.location.search;
    fetch(`${API_URL}/auth${queryParams}`)
    .then(response => response.json()) 
    .then(response => {
      Utils.setCookie(TOKEN, response.token);
    })
    .then(() => {
      Utils.getOrders({ callback : ({ orders, success }: getOrdersCallbackArgs) => {
        setOrders(orders);

        if (success) {
          handleUpdateAgreements();
        }
      }, setPadingationLinks,
    });

      Utils.getShopDetails({callback : ({ shopDetails }: getShopDetailsArgsCallbackArgs) => {
        setShopDetails(shopDetails);
      }})

      Utils.getLocations({callback : ({ locationsList }: getLocationsArgsCallbackArgs) => {
        setLocationsList(locationsList);
      }})
    })
  }, []);

  const handleLoadPrevOrders = () => {
    setIsFetching(true);
    Utils.getOrders({ callback : ({ orders }: getOrdersCallbackArgs) => {
      setOrders(orders);
      setIsFetching(false);
    }, setPadingationLinks, requestLink: padingationLinks.prevLink});
  }

  const handleLoadNextOrders = () => {
    setIsFetching(true);
    Utils.getOrders({ callback : ({ orders }: getOrdersCallbackArgs) => {
      setOrders(orders);
      setIsFetching(false);
    }, setPadingationLinks, requestLink: padingationLinks.nextLink});
  }

  if (confirmUrl) {
    return (
      <ReactivateSubscription 
        confirmUrl={confirmUrl}
      /> 
    )
  }

  return isFetching && locationsList[0] && Object.keys(locationsList[0]) ? null: 
  (
    <Fragment>
      <Header
        shopDetails={shopDetails}
        setShopDetails={setShopDetails}
        handleUpdateAgreements={handleUpdateAgreements}
        setShowInfoPage={setShowInfoPage}
      />
      {showInfoPage ? (
          <InfoPage 
            setShowInfoPage={setShowInfoPage}
          />
        ) : (
          <OrdersTable 
            orders={orders} 
            handleUpdateOrdersList={handleUpdateOrdersList}
            agreements={agreements}
            carriers={carriers}
            listCarriersOptions={listCarriersOptions}
            locationsList={locationsList}
            errorsList={errorsList}
            setErrorsList={setErrorsList}
            shopDetails={shopDetails}
            isApiKeyInvalidFlag={isApiKeyInvalidFlag}
            handleLoadPrevOrders={handleLoadPrevOrders}
            handleLoadNextOrders={handleLoadNextOrders}
            padingationLinks={padingationLinks}
            setShowInfoPage={setShowInfoPage}
          />
        )
      }
    </Fragment>
  ); 
}

export default OrdersList;
