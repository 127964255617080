import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import '@shopify/polaris/styles.css';
import '../styles/index.css';

import OrdersList from './OrdersList/OrdersList';

const App = () => {
  return (
    <Router>
      <Route exact path="/" component={OrdersList} />
    </Router>
  );
}

export default App;
